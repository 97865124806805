import {Link} from "react-router-dom";
import React from "react";
import {appImages} from "../utils/appImages";
import { Carousel } from "flowbite-react";
import { sliderTime } from "../utils/uiActions";

const Gallery = (): JSX.Element => {
    return (
        <>
            <div className={'bg-transparent'}>

                <div className={'w-full flex flex-row mt-[70px] justify-center'}>
                    <h1 className={'text text-3xl font-extrabold text-white'}>Gallery</h1>
                </div>

                <div className={'mt-2 mb-[70px] flex flex-row justify-center items-center'}>
                    <Link to={'/'} className={'text text-xl font-bold text-white mx-2 hover:cursor-pointer hover:text-[#be2332]'}>Home</Link>
                    <span className={'material-icons text-white font-bold mx-2'}>chevron_right</span>
                    <h2 className={'text text-xl font-bold text-white mx-2'}>Gallery</h2>
                </div>

                <div className={'bg-zinc-800 flex flex-row justify-center py-[50px]'}>

                    <div className={'container'}>

                        <div className={'flex flex-row justify-center mt-7'}>
                            <h1 className={'text text-4xl text-white font-bold'}>Explore Our Camp</h1>
                        </div>

                        <div className={'flex flex-row mt-4 justify-center'}>
                            <h1 className={'text text-white text-lg font-light'}>
                                Peek Into Our World.
                            </h1>
                        </div>

                        <div className={'h-[450px] md:h-[550px] 2xl:h-[700px] mt-10'}>

                         <Carousel slide={true} slideInterval={sliderTime}>

                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.bar_2}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_10}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_1}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_4}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.lounge_1}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_7}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.lounge_2}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.bar_3}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.comfortable_friendly}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.path_night}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.pool_1}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_8}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_6}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.rooms_5}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.restaurant_3}
                                 alt={'name'}/>
                            <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                 src={appImages.small_exclusive}
                                 alt={'name'}/>

                         </Carousel>

                        </div>


                    </div>

                </div>

            </div>

        </>
    )
}

export default Gallery;