import { Carousel } from "flowbite-react"
import { appImages } from "../utils/appImages"
import { Link } from "react-router-dom"
import { sliderTime } from "../utils/uiActions"

export const Rooms = ():JSX.Element => {
    return (
        <>
                <div className={'w-full flex flex-row mt-[70px] justify-center'}>
                    <h1 className={'text text-3xl font-extrabold text-white'}>Rooms</h1>
                </div>

                <div className={'mt-2 mb-[70px] flex flex-row justify-center items-center'}>
                    <Link to={'/'} className={'text text-xl font-bold text-white mx-2 hover:cursor-pointer hover:text-[#be2332]'}>Home</Link>
                    <span className={'material-icons text-white font-bold mx-2'}>chevron_right</span>
                    <h2 className={'text text-xl font-bold text-white mx-2'}>Our Rooms</h2>
                </div>
            
            <div className={'bg-zinc-800 flex flex-row justify-center py-[50px] px-10'}>

                <div className={'container'}>

                    <div className={'flex flex-row justify-center mt-7'}>
                        <h1 className={'text text-4xl text-white font-bold text-center'}>Private, Exclusive & Elegant Living</h1>
                    </div>

                    <div className="h-[450px] md:h-[550px] 2xl:h-[700px] mt-1 md:px-10 px-2">
                        <Carousel slideInterval={sliderTime}>
                                    <img 
                                        className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_11}
                                        alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_5}
                                        alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_1}
                                        alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_10}
                                        alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_7}
                                        alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_6}
                                        alt={'name'}/>
                                    <img className={'object-cover rounded-lg drop-shadow-2xl'}
                                        src={appImages.rooms_3}
                                        alt={'name'}/>
                        </Carousel>
                    </div>


                    <div className={'flex flex-col md:flex-row items-center mt-14 md:px-10 px-2'}>
                        <div className={'flex flex-col'}>
                            <div className={'flex flex-row'}>
                                <h1 className={'text text-white text-2xl font-medium'}>Our Rooms</h1>
                            </div>

                            <div className={'flex flex-row mt-4'}>
                                <h1 className={'text text-white text-lg font-light'}>
                                    Small and exclusive, comprising 25 tents. The tents are furnished with the understated luxury required by the modern traveler.<br/>
                                    Decorated in calming safari tones, natural woods, wrought iron and crisp cottons each tent has been meticulously planned to maximise comfort.<br/><br/>
                                    All tents have the following facilities:
                                </h1>
                            </div>

                            <div className={'flex flex-row'}>
                                <ul className={'list-inside list-disc text-thin text-lg text-white'}>
                                    <li>four poster beds & mosquito nets</li>
                                    <li>en-suite with shower, WC and flush toilets</li>
                                    <li>writing desk</li>
                                    <li>a private verandah overlooking the plains</li>
                                    <li>hot and cold running water</li>
                                    <li>voltage at 240 volts</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                        

                </div>

            </div>
        </>
    )
}
